/* @import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLTi9jKYd1gJzj5O2gWsEpXoyck2WCYPEMNySjZN0CHed_kmCvy597kDopqFAXvpeaA'); */

ol.lst-kix_list_7-0 {
    list-style-type: none
}

ul.lst-kix_list_1-0 {
    list-style-type: none
}

ol.lst-kix_list_9-0.start {
    counter-reset: lst-ctn-kix_list_9-0 0
}

.lst-kix_list_3-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_5-7 {
    list-style-type: none
}

ul.lst-kix_list_9-3 {
    list-style-type: none
}

ul.lst-kix_list_5-8 {
    list-style-type: none
}

ul.lst-kix_list_9-4 {
    list-style-type: none
}

.lst-kix_list_3-1>li:before {
    content: " "
}

.lst-kix_list_3-2>li:before {
    content: " "
}

ul.lst-kix_list_5-5 {
    list-style-type: none
}

ul.lst-kix_list_9-1 {
    list-style-type: none
}

ul.lst-kix_list_5-6 {
    list-style-type: none
}

ul.lst-kix_list_9-2 {
    list-style-type: none
}

.lst-kix_list_8-1>li:before {
    content: " "
}

ul.lst-kix_list_9-7 {
    list-style-type: none
}

ul.lst-kix_list_9-8 {
    list-style-type: none
}

.lst-kix_list_8-2>li:before {
    content: " "
}

ul.lst-kix_list_9-5 {
    list-style-type: none
}

.lst-kix_list_5-0>li {
    counter-increment: lst-ctn-kix_list_5-0
}

.lst-kix_list_6-0>li {
    counter-increment: lst-ctn-kix_list_6-0
}

.lst-kix_list_7-0>li {
    counter-increment: lst-ctn-kix_list_7-0
}

.lst-kix_list_8-0>li {
    counter-increment: lst-ctn-kix_list_8-0
}

.lst-kix_list_9-0>li {
    counter-increment: lst-ctn-kix_list_9-0
}

ul.lst-kix_list_9-6 {
    list-style-type: none
}

ul.lst-kix_list_1-3 {
    list-style-type: none
}

.lst-kix_list_3-5>li:before {
    content: " "
}

ul.lst-kix_list_1-4 {
    list-style-type: none
}

ul.lst-kix_list_1-1 {
    list-style-type: none
}

.lst-kix_list_3-4>li:before {
    content: " "
}

ul.lst-kix_list_1-2 {
    list-style-type: none
}

ul.lst-kix_list_5-3 {
    list-style-type: none
}

ul.lst-kix_list_1-7 {
    list-style-type: none
}

.lst-kix_list_3-3>li:before {
    content: " "
}

ul.lst-kix_list_5-4 {
    list-style-type: none
}

ul.lst-kix_list_1-8 {
    list-style-type: none
}

ul.lst-kix_list_5-1 {
    list-style-type: none
}

.lst-kix_list_8-0>li:before {
    content: "" counter(lst-ctn-kix_list_8-0, decimal) ". "
}

ul.lst-kix_list_1-5 {
    list-style-type: none
}

ul.lst-kix_list_5-2 {
    list-style-type: none
}

ul.lst-kix_list_1-6 {
    list-style-type: none
}

.lst-kix_list_8-7>li:before {
    content: " "
}

.lst-kix_list_3-8>li:before {
    content: " "
}

.lst-kix_list_8-5>li:before {
    content: " "
}

.lst-kix_list_8-6>li:before {
    content: " "
}

.lst-kix_list_8-3>li:before {
    content: " "
}

.lst-kix_list_3-6>li:before {
    content: " "
}

.lst-kix_list_3-7>li:before {
    content: " "
}

.lst-kix_list_8-4>li:before {
    content: " "
}

ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0
}

.lst-kix_list_8-8>li:before {
    content: " "
}

.lst-kix_list_5-0>li:before {
    content: "" counter(lst-ctn-kix_list_5-0, decimal) ". "
}

ol.lst-kix_list_6-0 {
    list-style-type: none
}

.lst-kix_list_4-8>li:before {
    content: " "
}

.lst-kix_list_5-3>li:before {
    content: " "
}

.lst-kix_list_4-7>li:before {
    content: " "
}

.lst-kix_list_5-2>li:before {
    content: " "
}

.lst-kix_list_5-1>li:before {
    content: " "
}

ul.lst-kix_list_4-8 {
    list-style-type: none
}

.lst-kix_list_5-7>li:before {
    content: " "
}

ul.lst-kix_list_8-4 {
    list-style-type: none
}

ul.lst-kix_list_8-5 {
    list-style-type: none
}

ul.lst-kix_list_4-6 {
    list-style-type: none
}

.lst-kix_list_5-6>li:before {
    content: " "
}

.lst-kix_list_5-8>li:before {
    content: " "
}

ul.lst-kix_list_8-2 {
    list-style-type: none
}

ul.lst-kix_list_4-7 {
    list-style-type: none
}

ul.lst-kix_list_8-3 {
    list-style-type: none
}

ul.lst-kix_list_8-8 {
    list-style-type: none
}

ul.lst-kix_list_8-6 {
    list-style-type: none
}

ul.lst-kix_list_8-7 {
    list-style-type: none
}

ul.lst-kix_list_4-0 {
    list-style-type: none
}

ul.lst-kix_list_4-1 {
    list-style-type: none
}

.lst-kix_list_5-4>li:before {
    content: " "
}

ul.lst-kix_list_4-4 {
    list-style-type: none
}

.lst-kix_list_5-5>li:before {
    content: " "
}

ul.lst-kix_list_4-5 {
    list-style-type: none
}

ul.lst-kix_list_8-1 {
    list-style-type: none
}

ul.lst-kix_list_4-2 {
    list-style-type: none
}

ul.lst-kix_list_4-3 {
    list-style-type: none
}

.lst-kix_list_6-1>li:before {
    content: " "
}

.lst-kix_list_6-3>li:before {
    content: " "
}

.lst-kix_list_6-0>li:before {
    content: "" counter(lst-ctn-kix_list_6-0, decimal) ". "
}

.lst-kix_list_6-4>li:before {
    content: " "
}

.lst-kix_list_6-2>li:before {
    content: " "
}

.lst-kix_list_6-8>li:before {
    content: " "
}

ol.lst-kix_list_8-0.start {
    counter-reset: lst-ctn-kix_list_8-0 0
}

.lst-kix_list_6-5>li:before {
    content: " "
}

.lst-kix_list_6-7>li:before {
    content: " "
}

.lst-kix_list_7-0>li:before {
    content: "" counter(lst-ctn-kix_list_7-0, decimal) ". "
}

.lst-kix_list_6-6>li:before {
    content: " "
}

ol.lst-kix_list_5-0 {
    list-style-type: none
}

.lst-kix_list_2-6>li:before {
    content: " "
}

.lst-kix_list_2-7>li:before {
    content: " "
}

.lst-kix_list_7-4>li:before {
    content: " "
}

.lst-kix_list_7-6>li:before {
    content: " "
}

.lst-kix_list_2-4>li:before {
    content: " "
}

.lst-kix_list_2-5>li:before {
    content: " "
}

.lst-kix_list_2-8>li:before {
    content: " "
}

.lst-kix_list_7-1>li:before {
    content: " "
}

.lst-kix_list_7-5>li:before {
    content: " "
}

.lst-kix_list_7-2>li:before {
    content: " "
}

.lst-kix_list_7-3>li:before {
    content: " "
}

ul.lst-kix_list_7-5 {
    list-style-type: none
}

.lst-kix_list_10-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_7-6 {
    list-style-type: none
}

ul.lst-kix_list_7-3 {
    list-style-type: none
}

ul.lst-kix_list_3-7 {
    list-style-type: none
}

ul.lst-kix_list_7-4 {
    list-style-type: none
}

ul.lst-kix_list_3-8 {
    list-style-type: none
}

.lst-kix_list_10-1>li:before {
    content: " "
}

ul.lst-kix_list_7-7 {
    list-style-type: none
}

ul.lst-kix_list_7-8 {
    list-style-type: none
}

ul.lst-kix_list_3-1 {
    list-style-type: none
}

ul.lst-kix_list_3-2 {
    list-style-type: none
}

.lst-kix_list_7-8>li:before {
    content: " "
}

ul.lst-kix_list_3-0 {
    list-style-type: none
}

ul.lst-kix_list_7-1 {
    list-style-type: none
}

ul.lst-kix_list_3-5 {
    list-style-type: none
}

ul.lst-kix_list_7-2 {
    list-style-type: none
}

ol.lst-kix_list_9-0 {
    list-style-type: none
}

ul.lst-kix_list_3-6 {
    list-style-type: none
}

ul.lst-kix_list_3-3 {
    list-style-type: none
}

.lst-kix_list_7-7>li:before {
    content: " "
}

ul.lst-kix_list_3-4 {
    list-style-type: none
}

.lst-kix_list_10-7>li:before {
    content: " "
}

.lst-kix_list_10-5>li:before {
    content: " "
}

.lst-kix_list_10-4>li:before {
    content: " "
}

.lst-kix_list_10-8>li:before {
    content: " "
}

.lst-kix_list_4-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_10-3>li:before {
    content: " "
}

.lst-kix_list_4-1>li:before {
    content: " "
}

.lst-kix_list_10-2>li:before {
    content: " "
}

.lst-kix_list_4-4>li:before {
    content: " "
}

.lst-kix_list_9-2>li:before {
    content: " "
}

.lst-kix_list_4-3>li:before {
    content: " "
}

.lst-kix_list_4-5>li:before {
    content: " "
}

.lst-kix_list_4-2>li:before {
    content: " "
}

.lst-kix_list_4-6>li:before {
    content: " "
}

.lst-kix_list_9-3>li:before {
    content: " "
}

ol.lst-kix_list_7-0.start {
    counter-reset: lst-ctn-kix_list_7-0 0
}

.lst-kix_list_9-0>li:before {
    content: "" counter(lst-ctn-kix_list_9-0, decimal) ". "
}

.lst-kix_list_10-6>li:before {
    content: " "
}

.lst-kix_list_9-1>li:before {
    content: " "
}

.lst-kix_list_9-7>li:before {
    content: " "
}

.lst-kix_list_9-6>li:before {
    content: " "
}

.lst-kix_list_9-4>li:before {
    content: " "
}

.lst-kix_list_9-5>li:before {
    content: " "
}

ul.lst-kix_list_6-6 {
    list-style-type: none
}

ul.lst-kix_list_6-7 {
    list-style-type: none
}

ul.lst-kix_list_6-4 {
    list-style-type: none
}

ul.lst-kix_list_2-8 {
    list-style-type: none
}

ul.lst-kix_list_6-5 {
    list-style-type: none
}

ul.lst-kix_list_6-8 {
    list-style-type: none
}

ul.lst-kix_list_2-2 {
    list-style-type: none
}

.lst-kix_list_1-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_2-3 {
    list-style-type: none
}

ul.lst-kix_list_2-0 {
    list-style-type: none
}

ul.lst-kix_list_2-1 {
    list-style-type: none
}

ul.lst-kix_list_6-2 {
    list-style-type: none
}

ol.lst-kix_list_8-0 {
    list-style-type: none
}

.lst-kix_list_9-8>li:before {
    content: " "
}

ul.lst-kix_list_2-6 {
    list-style-type: none
}

ul.lst-kix_list_6-3 {
    list-style-type: none
}

.lst-kix_list_1-1>li:before {
    content: " "
}

.lst-kix_list_1-2>li:before {
    content: " "
}

ul.lst-kix_list_2-7 {
    list-style-type: none
}

ul.lst-kix_list_2-4 {
    list-style-type: none
}

ul.lst-kix_list_6-1 {
    list-style-type: none
}

ul.lst-kix_list_2-5 {
    list-style-type: none
}

ul.lst-kix_list_10-0 {
    list-style-type: none
}

.lst-kix_list_1-3>li:before {
    content: " "
}

.lst-kix_list_1-4>li:before {
    content: " "
}

ul.lst-kix_list_10-8 {
    list-style-type: none
}

ul.lst-kix_list_10-7 {
    list-style-type: none
}

.lst-kix_list_1-7>li:before {
    content: " "
}

ul.lst-kix_list_10-6 {
    list-style-type: none
}

ul.lst-kix_list_10-5 {
    list-style-type: none
}

ul.lst-kix_list_10-4 {
    list-style-type: none
}

ul.lst-kix_list_10-3 {
    list-style-type: none
}

.lst-kix_list_1-5>li:before {
    content: " "
}

.lst-kix_list_1-6>li:before {
    content: " "
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

ul.lst-kix_list_10-2 {
    list-style-type: none
}

ul.lst-kix_list_10-1 {
    list-style-type: none
}

.lst-kix_list_2-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_2-1>li:before {
    content: " "
}

ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 0
}

.lst-kix_list_1-8>li:before {
    content: " "
}

.lst-kix_list_2-2>li:before {
    content: " "
}

.lst-kix_list_2-3>li:before {
    content: " "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c17 {
    background-color: #ffffff;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Poppins";
    font-style: normal
}

.c6 {
    padding-top: 0pt;
    border-bottom-color: #4f81bd;
    border-bottom-width: 1pt;
    padding-bottom: 4pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c15 {
    background-color: #ffffff;
    color: #222222;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Poppins";
    font-style: normal
}

.c19 {
    background-color: #ffffff;
    color: #222222;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Poppins";
    font-style: normal
}

.c8 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Poppins";
    font-style: normal
}

.c14 {
    color: #17365d;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 26pt;
    font-family: "Poppins";
    font-style: normal
}

.c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Poppins";
    font-style: normal
}

.c7 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c10 {
    padding-top: 10pt;
    padding-bottom: 0pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c2 {
    color: #4f81bd;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Poppins";
    font-style: normal
}

.c0 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c4 {
    color: #4f81bd;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13pt;
    font-family: "Poppins";
    font-style: normal
}

.c9 {
    padding-top: 0pt;
    padding-bottom: 10pt;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c20 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c16 {
    background-color: #ffffff;
    padding: 72pt 90pt 72pt 90pt
}

.c3 {
    padding: 0;
    margin: 0
}

.c11 {
    color: inherit;
    text-decoration: inherit
}

.c18 {
    margin-left: 54pt
}

.c13 {
    margin-left: 36pt
}

.c12 {
    margin-left: 18pt
}

.c5 {
    padding-left: 0pt
}

.title {
    padding-top: 0pt;
    color: #17365d;
    border-bottom-color: #4f81bd;
    border-bottom-width: 1pt;
    font-size: 26pt;
    padding-bottom: 4pt;
    font-family: "Poppins";
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #4f81bd;
    font-size: 12pt;
    padding-bottom: 10pt;
    font-family: "Poppins";
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 12pt;
    font-family: "Poppins"
}

p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: "Poppins"
}

h1 {
    padding-top: 24pt;
    color: #366091;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 0pt;
    font-family: "Poppins";
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 10pt;
    color: #4f81bd;
    font-weight: 700;
    font-size: 13pt;
    padding-bottom: 0pt;
    font-family: "Poppins";
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 10pt;
    color: #4f81bd;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 0pt;
    font-family: "Poppins";
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 10pt;
    color: #4f81bd;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 0pt;
    font-family: "Poppins";
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 10pt;
    color: #243f61;
    font-size: 12pt;
    padding-bottom: 0pt;
    font-family: "Poppins";
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 10pt;
    color: #243f61;
    font-size: 12pt;
    padding-bottom: 0pt;
    font-family: "Poppins";
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}